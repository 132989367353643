<legend>{{{o.__("Account Registration:")}}} {{{o.domain}}}</legend>
<p class="title">{{{o.title}}}</p>
<p class="form-help instructions">{{{o.instructions}}}</p>
<div class="form-errors hidden"></div>

<fieldset class="buttons">
    <input type="submit" class="btn btn-primary" value="{{{o.__('Register')}}}"/>
    {[ if (!o.registration_domain) { ]}
        <input type="button" class="btn btn-secondary button-cancel" value="{{{o.__('Choose a different provider')}}}"/>
    {[ } ]}
</fieldset>
