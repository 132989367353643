<div class="chatbox-navback"><i class="fa fa-arrow-left"></i></div>
<div class="chatbox-title">
    <div class="chat-title">
        {[ if (o.name) { ]}
            {{{ o.name }}}
        {[ } else { ]}
            {{{ o.Strophe.getNodeFromJid(o.jid) }}}
        {[ } ]}
    </div>
    <p class="chatroom-description" title="{{{o.description}}}">{{{o.description}}}</p>
</div>
<div class="chatbox-buttons row no-gutters">
    <a class="chatbox-btn close-chatbox-button fa fa-times" title="{{{o.info_close}}}"></a>
    {[ if (o.affiliation == 'owner') { ]}
    <a class="chatbox-btn configure-chatroom-button fa fa-wrench" title="{{{o.info_configure}}} "></a>
    {[ } ]}
    <a class="chatbox-btn toggle-occupants fa fa-users" title="Toggle occupants"></a>
    <!-- <a class="chatbox-btn show-room-details-modal fa fa-info-circle" title="{{{o.info_details}}}"></a> -->
</div>
