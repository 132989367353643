<!-- Add contact Modal -->
<div class="modal fade" id="add-contact-modal" tabindex="-1" role="dialog" aria-labelledby="addContactModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h5 class="modal-title" id="addContactModalLabel">{{{o.heading_new_contact}}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div> -->
            <form class="converse-form add-xmpp-contact">
                <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-top: -25px;">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="form-group {[ if (o._converse.xhr_user_search_url) { ]} hidden {[ } ]}">
                        <label class="clearfix" for="jid">{{{o.label_xmpp_address}}}:</label>
                        <input type="text" name="jid" required="required" value="{{{o.jid}}}"
                               class="form-control"
                               placeholder="{{{o.contact_placeholder}}}"/>
                        <div class="invalid-feedback">{{{o.error_message}}}</div>
                    </div>
                    <div class="form-group" style="display: none;">
                        <label class="clearfix" for="name">{{{o.label_nickname}}}:</label>
                        <input type="text" name="name" value="{{{o.nickname}}}"
                               class="form-control"
                               placeholder="{{{o.nickname_placeholder}}}"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">{{{o.label_add}}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
