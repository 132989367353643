<div id="converse-login-panel" class="controlbox-pane fade-in row no-gutters">
    <form id="converse-login" class="converse-form" method="post">
        <div class="conn-feedback fade-in {[ if (!o.conn_feedback_subject) { ]} hidden {[ } ]} {{{o.conn_feedback_class}}}">
            <p class="feedback-subject">{{{ o.conn_feedback_subject }}}</p>
            <p class="feedback-message {[ if (!o.conn_feedback_message) { ]} hidden {[ } ]}">{{{o.conn_feedback_message}}}</p>
        </div>
        {[ if (o.auto_login || o._converse.CONNECTION_STATUS[o.connection_status] === 'CONNECTING') { ]}
            <span class="spinner fa fa-spinner centered"/>
        {[ } else { ]}
            {[ if (o.authentication == o.LOGIN || o.authentication == o.EXTERNAL) { ]}
                <div class="form-group">
                    <label for="converse-login-jid">{{{o.__("XMPP Username:")}}}</label>
                    <input id="converse-login-jid" class="form-control" autofocus required="required" type="text" name="jid" placeholder="{{{o.placeholder_username}}}">
                </div>
                {[ if (o.authentication !== o.EXTERNAL) { ]}
                <div class="form-group">
                    <label for="converse-login-password">{{{o.__("Password:")}}}</label>
                    <input id="converse-login-password" class="form-control" required="required" type="password" name="password" placeholder="{{{o.__('password')}}}">
                </div>
                {[ } ]}
                <div class="form-group form-check">
                    <input id="converse-login-trusted" type="checkbox" class="form-check-input" name="trusted" {[ if (o._converse.config.get('trusted')) { ]} checked="checked" {[ } ]}>
                    <label for="converse-login-trusted" class="form-check-label">{{{o.__('This is a trusted device')}}}</label>
                    <i class="fa fa-info-circle" data-toggle="popover"
                       data-title="Trusted device?"
                       data-content="{{{o.__('To improve performance, we cache your data in this browser. Uncheck this box if this is a public computer or if you want your data to be deleted when you log out. It\'s important that you explicitly log out, otherwise not all cached data might be deleted.')}}}"></i>
                </div>

                <fieldset class="buttons">
                    <input class="btn btn-primary" type="submit" value="{{{o.__('Log in')}}}">
                </fieldset>
            {[ } ]}
            {[ if (o.authentication == o.ANONYMOUS) { ]}
                <input class="btn btn-primary login-anon" type="submit" value="{{{o.__('Click here to log in anonymously')}}}"/>
            {[ } ]}
            {[ if (o.authentication == o.PREBIND) { ]}
                <p>Disconnected.</p>
            {[ } ]}
        {[ } ]}
    </form>
</div>
