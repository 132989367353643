<div class="userinfo controlbox-padded">
<div class="profile d-flex">
    <a class="show-profile" href="#">
        <canvas alt="o.__('Your avatar')" class="avatar align-self-center" height="40" width="40"></canvas>
    </a>
    <span class="username w-100 align-self-center">{{{o.fullname}}}</span>
    <!-- <a class="chatbox-btn fa fa-id-card align-self-center" title="{{{o.title_your_profile}}}" data-toggle="modal" data-target="#userProfileModal"></a> -->
    <!-- <a class="chatbox-btn fa fa-cog align-self-center" title="{{{o.title_change_status}}}" data-toggle="modal" data-target="#settingsModal"></a> -->
    {[ if (o._converse.allow_logout) { ]}
        <a class="chatbox-btn logout fa fa-sign-out-alt align-self-center" title="{{{o.title_log_out}}}"></a>
    {[ } ]}
</div>
<div class="d-flex xmpp-status">
    <span class="{{{o.chat_status}}} w-100 align-self-center" data-value="{{{o.chat_status}}}">
        <span class="
            {[ if (o.chat_status === 'online') { ]} fa fa-circle {[ } ]}
            {[ if (o.chat_status === 'dnd') { ]} fa fa-minus-circle {[ } ]}
            {[ if (o.chat_status === 'away') { ]} fa fa-dot-circle {[ } ]}
            {[ if (o.chat_status === 'xa') { ]} far fa-circle {[ } ]}
            {[ if (o.chat_status === 'offline') { ]} fa fa-times-circle {[ } ]}"></span> {{{o.status_message}}}</span>
    <a class="chatbox-btn change-status fa fa-pencil-alt" title="{{{o.title_change_status}}}" data-toggle="modal" data-target="#changeStatusModal"></a>
</div>
</div>
