<div class="chatroom-form-container">
    <form class="converse-form chatroom-form">
        <legend>{{{o.heading}}}</legend>
        <fieldset class="form-group">
            <label for="converse_muc_bookmark_name">{{{o.label_name}}}</label>
            <input class="form-control" type="text" name="name" required="required" id="converse_muc_bookmark_name"/>
        </fieldset>
        <fieldset class="form-group">
            <label for="converse_muc_bookmark_nick">{{{o.label_nick}}}</label>
            <input class="form-control" type="text" name="nick" value="{{{o.default_nick}}}" id="converse_muc_bookmark_nick"/>
        </fieldset>
        <fieldset class="form-group form-check">
            <input class="form-check-input" id="converse_muc_bookmark_autojoin" type="checkbox" name="autojoin"/>
            <label class="form-check-label" for="converse_muc_bookmark_autojoin">{{{o.label_autojoin}}}</label>
        </fieldset>
        <fieldset class="form-group">
            <input class="btn btn-primary" type="submit" value="{{{o.label_submit}}}"/>
            <input class="btn btn-secondary button-cancel" type="button" value="{{{o.label_cancel}}}"/>
        </fieldset>
    </form>
</div>
