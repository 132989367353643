<div class="modal fade" id="add-chatroom-modal" tabindex="-1" role="dialog" aria-labelledby="add-chatroom-modal-label" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h5 class="modal-title"
                    id="add-chatroom-modal-label">{{{o.heading_new_chatroom}}}</h5>
            </div> -->
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <form class="converse-form add-chatroom">
                    <div class="form-group">
                        <label for="chatroom">{{{o.label_room_address}}}:</label>
                        <input type="text" required="required" name="chatroom" class="form-control" placeholder="{{{o.chatroom_placeholder}}}">
                    </div>
                    <div class="form-group" style="display: none;">
                        <label for="nickname">{{{o.label_nickname}}}:</label>
                        <input type="text" name="nickname" value="{{{o.nick}}}" class="form-control">
                    </div>
                    <input type="submit" class="btn btn-primary" name="join" value="{{{o.label_join}}}">
                </form>
            </div>
        </div>
    </div>
</div>
