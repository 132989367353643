{[ if (o.label) { ]}
<label>
    {{{o.label}}}
</label>
{[ } ]}
<div class="input-group">
    <input name="{{{o.name}}}" type="{{{o.type}}}"
        {[ if (o.value) { ]} value="{{{o.value}}}" {[ } ]}
        {[ if (o.required) { ]} class="required" {[ } ]} />
    <span title="{{{o.domain}}}">{{{o.domain}}}</span>
</div>
