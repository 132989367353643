<li class="room-item list-group-item">
  <div class="available-chatroom d-flex flex-row">
    <a class="open-room available-room w-100"
       data-room-jid="{{{o.jid}}}"
       data-room-name="{{{o.name}}}"
       title="{{{o.open_title}}}"
       href="#">{{{o.name}}}</a>
    <a class="right room-info icon-room-info"
       data-room-jid="{{{o.jid}}}"
       title="{{{o.info_title}}}" href="#">&nbsp;</a>
  </div>
</li>
